import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  useToast,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  Textarea,
  useColorMode,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react"
import axios from "axios"
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { BsPerson } from "react-icons/bs"
import { MdEmail, MdPhone } from "react-icons/md"
import BannerBlepharo from "../components/choc/BannerBlepharo"
import BannerHyaloron from "../components/choc/BannerHyaloron"
import LogoHeadlineTeaserVeo from "../components/choc/LogoHeadlineTeaserVEO"
import QuadTeaser from "../components/choc/QuadTeaser"
import ImgTextModule from "../components/ImgTextModule"
import Layout from "../components/layout/Layout"
import TwoColumnGridBoxesAllgemein from "../components/ThreeColumnGridBoxesAllgemein"
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation"

const Kontakt = () => {
  const toast = useToast()
  const {
    register,
    handleSubmit,
    reset,
    formState,
    formState: { errors, isSubmitting, isSubmitted, isSubmitSuccessful },
  } = useForm()

  function onSubmit(values) {
    return new Promise(resolve => {
      setTimeout(() => {
        axios.post("https://mailer-endpoint.veo-med.com/bewerbung.php", values)
        console.log(values)
        resolve()
      }, 1111)
    })
  }
  let { toggleColorMode, ColorMode, setColorMode } = useColorMode()
  useEffect(() => {
    setColorMode(colormodeValue)
  })
  const [viewportHeight, setViewportHeight] = useState(0)
  const [pageHeight, setPageHeight] = useState(0)
  useEffect(() => {
    setViewportHeight(window.innerHeight)
    setPageHeight(document.body.clientHeight)
    console.log("VIEWPORTHEIGHT: " + window.innerHeight)
    console.log("PAGEHEIGHT: " + document.body.clientHeight)
  }, [])
  const [colormodeValue, changeColorModeValue] = useState("light")
  return (
    <Layout
      title={"Display"}
      description={"The boundaries of motion-design are pushed in obscurity"}
    >
      <Container variant="layoutContainer" my={24} maxW={"1720px"}>
        <ImgTextModule
          w="100%"
          px={{ base: 0, md: 12, lg: 4, xl: 0 }}
          src={"short-master005.mp4"}
          delay={0.9}
          duration={1}
          threshold={0.4}
          initialY={0}
          initialX={0}
          video={true}
          colStart={1}
          colEnd={3}
          h={"90vh"}
          mediaOpacity={0.8}
          bgColor="black"
        >
          <FadeInAnimation threshold={0.4} duration={1}>
            <Box p={8}>
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text color="white" fontSize="2xl" fontWeight="bold">
                  Ein fantastisches Team
                </Text>
              </FadeInAnimation>
              <FadeInAnimation
                threshold={0.4}
                delay={0.6}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text color="white" fontSize="xl">
                  erwartet Dich.
                </Text>
              </FadeInAnimation>
              <FadeInAnimation
                threshold={0.4}
                delay={0.6}
                duration={1}
                initialX={0}
                initialY={-10}
              >
                <Text mt={4} color="white" fontSize="5xl">
                  Lass uns gemeinsam sprechen.
                </Text>
              </FadeInAnimation>
              <FadeInAnimation
                threshold={0.4}
                delay={0.6}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text color="white" fontSize="xl">
                  Wir melden uns umgehend bei Dir.
                </Text>
              </FadeInAnimation>
            </Box>
          </FadeInAnimation>
        </ImgTextModule>
      </Container>
      <Container variant="layoutContainer" mt={1}>
        <Flex>
          <Box
            bg="#fafafa"
            color="white"
            borderRadius="lg"
            w="100%"
            mx="auto"
            p={{ sm: 5, md: 5, lg: 16 }}
          >
            <Box p={4} w="100%">
              <Wrap
                w="100%"
                spacing={{ base: 20, sm: 3, md: 5, lg: 2 }}
                bg="white"
                borderRadius="lg"
                boxShadow="2xl"
              >
                <WrapItem w={{ base: "100%", lg: "100%" }}>
                  <Box w="100%">
                    <Box m={8} color="#0B0E3F">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <FormControl isInvalid={errors.name}>
                          <label>Name</label>
                          <InputGroup borderColor="#E0E1E7" my="4">
                            <InputLeftElement
                              pointerEvents="none"
                              children={<BsPerson color="gray.800" />}
                            />
                            <Input
                              type="text"
                              size="md"
                              {...register("Name")}
                            />
                          </InputGroup>

                          <label>Email</label>
                          <InputGroup borderColor="#E0E1E7" my="4">
                            <InputLeftElement
                              pointerEvents="none"
                              children={<BsPerson color="gray.800" />}
                            />
                            <Input
                              type="text"
                              size="md"
                              {...register("EMail")}
                            />
                          </InputGroup>
                          <label>Telefonnummer:</label>
                          <InputGroup borderColor="#E0E1E7" my="4">
                            <InputLeftElement pointerEvents="none" />
                            <Input
                              type="phone"
                              size="md"
                              {...register("Telnummer")}
                            />
                          </InputGroup>
                          <label>Nachricht</label>
                          <Textarea
                            size="md"
                            type="text"
                            placeholder="Wie sollen wir Dich am besten kontaktieren?"
                            name="nachricht"
                            {...register("Nachricht")}
                          />

                          <Button
                            mt={12}
                            variant="solid"
                            bg="#0D74FF"
                            color="white"
                            _hover={{}}
                            isLoading={isSubmitting}
                            type="submit"
                          >
                            Senden
                          </Button>
                        </FormControl>
                        <Box visibility={"hidden"}>
                          {isSubmitted &&
                            toast({
                              title: "Vielen Dank für Deine Nachricht!",
                              description: "Wir melden uns gern bei Dir!",
                              status: "success",
                              duration: 9000,
                              isClosable: true,
                            })}
                        </Box>
                      </form>
                    </Box>
                  </Box>
                </WrapItem>
              </Wrap>
            </Box>
          </Box>
        </Flex>
      </Container>
      <Container variant="layoutContainer" mt={12}>
        <TwoColumnGridBoxesAllgemein />
      </Container>
      <BannerBlepharo />
      <BannerHyaloron />
      <LogoHeadlineTeaserVeo
        headline="Ästhetik mit High-Tech"
        subheadline="Und Verantwortung"
      />
      <QuadTeaser />
    </Layout>
  )
}

export default Kontakt
